import { useQuery } from '@apollo/client';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';

import { useMap } from '@/map/lib/context';
import { TOURNAMENT_ID_QUERY } from '@/tournament/lib/tournament-id.query';

export interface EventCardProps {
	id: string;
}

export const MapEventCards = ({ id }: EventCardProps) => {
	const { data, loading } = useQuery(TOURNAMENT_ID_QUERY, {
		fetchPolicy: 'cache-and-network',
		variables: { id }
	});

	const router = useRouter();
	const { mapDisplay } = useMap();

	const getImageToDisplay = useMemo(() => {
		if (data && data.tournament) {
			if (data.tournament.logo.endsWith('placeholder/pickleball.png')) {
				return `${process.env.NEXT_PUBLIC_CDN_URL}/static/images/pickleball.png`;
			}

			return data.tournament.logo;
		}
	}, [data]);

	const classes = clsx(
		'absolute inset-x-4 bottom-16 z-30 flex cursor-pointer items-center gap-5 rounded-xl bg-white p-6 md:bottom-6 md:left-1/2 md:right-auto md:-ml-40 md:w-full',
		{
			'max-w-md': mapDisplay === 'full',
			'max-w-sm': mapDisplay === 'display'
		}
	);

	if (loading) {
		return (
			<div style={{ boxShadow: '0 6px 20px rgba(0,0,0,0.2)' }} className={classes}>
				<div className="flex w-full items-center justify-center py-2">
					<FontAwesomeIcon icon={faSpinnerThird} spin className="lg" />
				</div>
			</div>
		);
	}

	if (!data.tournament) {
		return (
			<div style={{ boxShadow: '0 6px 20px rgba(0,0,0,0.2)' }} className={classes}>
				<div className="flex w-full items-center justify-center py-2">Could not get tournament.</div>
			</div>
		);
	}

	const tourneyFromDate = new Date(data.tournament.dateFrom);
	const tourneyToDate = new Date(data.tournament.dateTo);

	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };

	const formattedFromDate = new Intl.DateTimeFormat('en-US', options).format(tourneyFromDate);
	const formattedToDate = new Intl.DateTimeFormat('en-US', options).format(tourneyToDate);

	return (
		<div
			style={{ boxShadow: '0 6px 20px rgba(0,0,0,0.2)' }}
			className={classes}
			onClick={() => {
				router.push(`${[process.env.NEXT_PUBLIC_PT_URL]}/tournaments/${data.tournament.slug}`);
			}}
		>
			<div className="w-28 shrink-0 rounded-lg border border-gray-200 p-2">
				<picture className="block overflow-hidden rounded-xl">
					<img src={getImageToDisplay} alt="" className="w-full max-w-full" />
				</picture>
			</div>
			<div className="flex-1 overflow-hidden">
				<h3 className="mt-1 truncate text-sm font-medium">{data.tournament.title}</h3>
				<div className="text-sm text-black/60">{`${formattedFromDate} - ${formattedToDate}`}</div>
				<div className="mt-1 text-xs">
					{data.tournament.registrationCount === 0 ? (
						'No registrations'
					) : (
						<div className="">
							<span className="text-sm font-semibold">{data.tournament.registrationCount}</span> registrations
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
