/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/ui/components/tournaments-app//button';
import { Pagination, PaginationContent, PaginationEllipsis } from '@pickleballinc/ui/components/tournaments-app//pagination';

import { usePagination } from './usePagination';

interface TournamentsPaginationProps {
	count: number;
	onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
	page?: number;
}

export const TournamentPagination = ({ count, onChange, page: initialPage }: TournamentsPaginationProps) => {
	const { items } = usePagination({
		count,
		onChange,
		showFirstButton: false,
		showLastButton: false,
		page: initialPage
	});

	return (
		<Pagination>
			<PaginationContent>
				{items.map(({ type, page, selected, ...item }) => {
					let children: React.ReactNode = null;

					if (type === 'start-ellipsis' || type === 'end-ellipsis') {
						children = <PaginationEllipsis key={type} />;
					} else if (type === 'page') {
						children = (
							<Button
								{...item}
								type="button"
								key={page}
								size="icon"
								className={`rounded-full border-none bg-transparent text-black hover:bg-transparent ${selected ? 'bg-black text-white hover:cursor-default hover:bg-black hover:text-white' : 'bg-transparent text-black hover:bg-transparent'}`}
							>
								{page}
							</Button>
						);
					} else {
						return (
							<Button
								{...item}
								type="button"
								key={type}
								size="icon"
								className={`rounded-full border-none bg-transparent text-black hover:bg-transparent ${type === 'previous' ? 'mr-4' : 'ml-4'}`}
							>
								<FontAwesomeIcon icon={type === 'previous' ? faAngleLeft : faAngleRight} size="sm" />
							</Button>
						);
					}

					return children;
				})}
			</PaginationContent>
		</Pagination>
	);
};
