'use client';

import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useMemo, useReducer, useState } from 'react';

import { reducer } from '@/map/lib/reducer';
import { LatLng } from '@/tournament/lib/context';

import { GOOGLE_MAP_API_KEY } from '../../map/lib/constants';
import { initialMapState, MapContext, MapDisplayState, MapState } from '../../map/lib/context';

interface MapProviderProps {
	children: React.ReactNode;
	initialState: Partial<MapState>;
}

const libraries: Libraries = ['places'];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MapProvider = ({ children, initialState }: MapProviderProps) => {
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [state, dispatch] = useReducer(reducer, { ...initialMapState, ...initialState });

	const { isLoaded } = useJsApiLoader({
		id: 'map',
		googleMapsApiKey: GOOGLE_MAP_API_KEY,
		libraries,
		version: '3.54.12a'
	});

	const onLoad = useCallback(function callback(map: google.maps.Map) {
		setMap(map);
	}, []);

	const onUnmount = useCallback(function callback() {
		setMap(null);
	}, []);

	const moveCamera = useCallback(() => {
		// animate camera movement to lat/lng
	}, []);

	const setMapDisplay = useCallback((state: MapDisplayState) => {
		dispatch({ type: 'SET_MAP_DISPLAY_STATE', payload: state });
	}, []);

	const setMapPosition = useCallback((center: LatLng, zoomLevel: number, programmaticZoom: boolean) => {
		dispatch({ type: 'SET_MAP_POSITION', payload: { center, zoomLevel, programmaticZoom } });
	}, []);

	const setShowAll = useCallback((showAll: boolean) => {
		dispatch({ type: 'SET_SHOW_ALL', payload: showAll });
	}, []);

	const setProgrammaticZoom = useCallback((programmaticZoom: boolean) => {
		dispatch({ type: 'SET_PROGRAMMATIC_ZOOM', payload: programmaticZoom });
	}, []);

	const setViewPort = useCallback((width: number, height: number) => {
		dispatch({ type: 'SET_MAP_VIEWPORT', payload: { width, height } });
	}, []);

	const resetToInitialState = useCallback((state: Partial<MapState>) => {
		dispatch({ type: 'RESET_TO_INITIAL_STATE', payload: state });
	}, []);

	const contextValue = useMemo(() => {
		return {
			...state,
			isLoaded,
			map,
			moveCamera,
			onLoad,
			onUnmount,
			setMapDisplay,
			setMapPosition,
			setProgrammaticZoom,
			setShowAll,
			setViewPort,
			resetToInitialState
		};
	}, [
		state,
		isLoaded,
		map,
		setMapDisplay,
		moveCamera,
		onLoad,
		onUnmount,
		setMapDisplay,
		setMapPosition,
		setProgrammaticZoom,
		setShowAll,
		setViewPort
	]);

	return <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>;
};
