import { faMap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';

import { TournamentFilters } from '@/components/TournamentFilters';
import { useMap } from '@/map/lib/context';
import { RemovePartnerSelection } from '@/tournament/components/RemovePartnerSelection';
import { useTournaments } from '@/tournament/lib/context';

interface TournamentCountProps {
	count?: number;
	globalCount: number;
	isLoading: boolean;
}

export const TournamentCount = ({ globalCount, count, isLoading }: TournamentCountProps) => {
	const { selectedTournamentFilter, setSelectedTournamentFilter, selectedPartner, setSelectedPartner } = useTournaments();
	const { mapDisplay, showAll, setMapDisplay } = useMap();

	if (isLoading) {
		return (
			<div className="m-6 text-sm font-semibold">
				<div role="status" className="shadow flex h-5 max-w-sm animate-pulse items-center rounded">
					<div className="h-5 w-64 rounded-md bg-gray-200 dark:bg-gray-700" />
				</div>
			</div>
		);
	}

	return (
		<div className={`flex gap-4 p-6 text-sm font-semibold md:flex ${mapDisplay === 'hidden' ? 'max-w-screen-2xl' : ''}`}>
			{(!!count || !!globalCount) && (
				<div className="flex flex-col gap-2">
					<div className="flex flex-row flex-wrap items-center gap-4">
						{!!count && <div className="text-lg">{`${count} results found`}</div>}
						{(selectedTournamentFilter || selectedPartner) && (
							<RemovePartnerSelection
								partner={selectedTournamentFilter !== null ? selectedTournamentFilter : selectedPartner!}
								onClick={() => {
									setSelectedTournamentFilter(null);
									setSelectedPartner(null);
								}}
							/>
						)}
					</div>
					{!!globalCount && (
						<div>
							{globalCount} Active Tournaments <span className="text-blue-500">Worldwide</span>
						</div>
					)}
				</div>
			)}

			<div className="flex items-center gap-2">
				{mapDisplay === 'hidden' && !showAll && (
					<div className="hidden lg:block">
						<Button
							size="sm"
							variant="secondary"
							className="shadow-none rounded-lg text-xs font-semibold hover:border-black"
							onClick={() => {
								setMapDisplay('display');
							}}
						>
							<FontAwesomeIcon icon={faMap} className="mr-2" />
							Show map
						</Button>
					</div>
				)}
				<div className="hidden md:flex">
					<TournamentFilters />
				</div>
			</div>
		</div>
	);
};
