import { initialMapState, MapDisplayState, MapState } from '@/map/lib/context';
import { LatLng } from '@/tournament/lib/context';

type MapActionType =
	| { type: 'SET_MAP_DISPLAY_STATE'; payload: MapDisplayState }
	| { type: 'SET_MAP_POSITION'; payload: { center: LatLng; zoomLevel: number; programmaticZoom: boolean } }
	| { type: 'SET_MAP_VIEWPORT'; payload: { width: number; height: number } }
	| { type: 'SET_PROGRAMMATIC_ZOOM'; payload: boolean }
	| { type: 'SET_SHOW_ALL'; payload: boolean }
	| { type: 'SET_ZOOM_LEVEL'; payload: number }
	| { type: 'RESET_TO_INITIAL_STATE'; payload: Partial<MapState> };

export const reducer = (state: MapState, action: MapActionType): MapState => {
	switch (action.type) {
		case 'SET_MAP_DISPLAY_STATE': {
			return {
				...state,
				mapDisplay: action.payload
			};
		}
		case 'SET_MAP_POSITION': {
			const { center, zoomLevel, programmaticZoom } = action.payload;
			return {
				...state,
				center,
				mapDisplay: 'display',
				showAll: false,
				zoomLevel,
				programmaticZoom
			};
		}
		case 'SET_PROGRAMMATIC_ZOOM': {
			return {
				...state,
				programmaticZoom: action.payload
			};
		}
		case 'SET_SHOW_ALL': {
			return {
				...state,
				mapDisplay: action.payload ? 'hidden' : 'display',
				showAll: action.payload
			};
		}
		case 'SET_MAP_VIEWPORT': {
			return {
				...state,
				viewport: action.payload
			};
		}
		case 'RESET_TO_INITIAL_STATE': {
			return {
				...initialMapState,
				...action.payload
			};
		}
		default: {
			return state;
		}
	}
};
