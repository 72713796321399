'use client';

import { useLazyQuery } from '@apollo/client';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { convertDateToUnix } from '@/lib/date';
import { useSession } from '@/lib/SessionProvider';
import { composeQueryParams } from '@/tournament/lib/compose-query';
import { getMapBounds } from '@/tournament/lib/get-bounds';
import { GooglePlaceResult } from '@/tournament/lib/google.type';

import { MapBounds, useMap } from '../../map/lib/context';
import { LatLng, TournamentsContext, TournamentsState } from '../lib/context';
import { getBoundsZoomLevel } from '../lib/maps';
import { reducer } from '../lib/reducer';
import { TOURNAMENT_QUERY } from '../lib/tournament.query';
import { TournamentQuery } from '../lib/tournament.type';

interface TournamentsProviderProps {
	children: React.ReactNode;
	initialState: TournamentsState;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TournamentsProvider = ({ children, initialState }: TournamentsProviderProps) => {
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const { session } = useSession();
	// use map context
	const { map, viewport, setMapPosition, setShowAll } = useMap();

	// tournaments state initialize
	// const [initialLoaded, setInitialLoaded] = useState(true);
	const [state, dispatch] = useReducer(reducer, initialState);

	const [fetch, { data, loading, previousData }] = useLazyQuery<TournamentQuery>(TOURNAMENT_QUERY, {
		context: {
			headers: {
				'PB-USER-TOKEN': session?.user?.token || ''
			}
		},
		fetchPolicy: 'cache-and-network'
	});

	const composeGraphQLVars = useMemo(() => {
		const {
			bounds,
			dates,
			keyword,
			limit,
			center,
			myTournaments,
			nowPlaying,
			nowRegistering,
			selectedTournamentFilter,
			userLocationFetch,
			managing,
			mtPast,
			page,
			featured,
			clubId,
			pastEvents,
			selectedPartner
		} = state;

		const startDate = convertDateToUnix(dates?.from);
		const endDate = convertDateToUnix(dates?.to);
		const showBounds =
			!myTournaments && !nowPlaying && !nowRegistering && !selectedTournamentFilter && !selectedPartner && (!keyword || keyword === '');
		let locationFetch = userLocationFetch;
		if (!bounds && showBounds) {
			locationFetch = true;
		}

		const variables = {
			bounds: showBounds && bounds ? bounds : null,
			keyword,
			limit,
			center,
			nowPlaying,
			clubId,
			nowRegistering,
			pastEvents,
			page,
			featured,
			myTournaments,
			mtManaging: managing,
			mtFuture: !pastEvents,
			mtPast,
			userLocationFetch: locationFetch,
			userId: session?.user?.pbUuid ? `${session.user.pbUuid}` : null,
			startDate,
			tournamentFilter: selectedTournamentFilter,
			endDate,
			partner: selectedPartner,
			requesterUuid: session?.user?.uuid ? `${session.user.uuid}` : null
		};
		const params = composeQueryParams(state, searchParams.toString());

		return {
			variables,
			params
		};
	}, [state, session, searchParams]);

	// Initial and Data fetch
	useEffect(() => {
		const { variables, params } = composeGraphQLVars;
		router.replace(`${pathname}?${params}`, { scroll: false });
		fetch({ variables });
		if (
			state.nowPlaying ||
			state.nowRegistering ||
			state.selectedTournamentFilter ||
			state.selectedPartner ||
			state.myTournaments ||
			state.keyword ||
			state.clubId ||
			state.featured ||
			state.placeKeyword === 'Anywhere'
		) {
			setShowAll(true);
		} else {
			setShowAll(false);
		}
	}, [
		state.limit,
		state.page,
		state.nowPlaying,
		state.mtPast,
		state.featured,
		state.myTournaments,
		state.managing,
		state.nowRegistering,
		state.selectedTournamentFilter,
		state.selectedPartner
	]);

	// Map move fetch
	useEffect(() => {
		if (state.bounds && state.mapArea) {
			const { variables, params } = composeGraphQLVars;
			router.replace(`${pathname}?${params}`, { scroll: false });
			fetch({ variables });
		}
	}, [state.bounds, state.mapArea]);

	// =====================================
	const clearDates = useCallback(() => {
		dispatch({ type: 'CLEAR_DATES' });
	}, [state]);

	// =====================================
	const getTournaments = useCallback(() => {
		const { variables, params } = composeGraphQLVars;
		router.replace(`${pathname}?${params.toString()}`, { scroll: false });
		if (state.center && state.zoomLevel) {
			setMapPosition(state.center, state.zoomLevel, true);
			map?.setZoom(state.zoomLevel);
		}

		if (!state.placeKeyword || state.nowPlaying || state.nowRegistering) {
			setPlaceKeyword('Anywhere');
		}
		if (
			(state.keyword && state.keyword !== '') ||
			state.myTournaments ||
			state.nowPlaying ||
			state.nowRegistering ||
			state.myTournaments ||
			state.featured ||
			state.placeKeyword === 'Anywhere' ||
			!state.placeKeyword
		) {
			setShowAll(true);
		} else {
			setShowAll(false);
		}
		fetch({ variables });
	}, [state]);

	// =====================================
	const setBounds = useCallback(
		(bounds: MapBounds | null, zoomLevel?: number, center?: { lat: number; lng: number }, mapArea?: boolean) => {
			if (viewport) {
				let getZoomLevel: number | undefined = undefined;
				let getCenter: LatLng | undefined = undefined;
				if (bounds) {
					const googleBounds = new google.maps.LatLngBounds(
						new google.maps.LatLng(bounds.sw_lat, bounds.sw_lng),
						new google.maps.LatLng(bounds.ne_lat, bounds.ne_lng)
					);

					getZoomLevel = zoomLevel || getBoundsZoomLevel(googleBounds, viewport);
					getCenter = center;
				}

				dispatch({
					type: 'SET_BOUNDS',
					payload: { bounds, center: getCenter, zoomLevel: getZoomLevel, mapArea: mapArea ? mapArea : false }
				});
			}
		},
		[fetch, state, viewport]
	);

	// =====================================
	const setDates = useCallback(({ from, to, months }: { from?: string; to?: string; months?: number }) => {
		dispatch({ type: 'SET_DATES', payload: { from, to, months } });
	}, []);

	// =
	const setFeatured = useCallback((featured: boolean) => {
		dispatch({ type: 'SET_FEATURED', payload: featured });
	}, []);

	// =====================================
	const setKeyword = useCallback((keyword: string | null) => {
		dispatch({ type: 'SET_KEYWORD', payload: keyword });
	}, []);

	// =====================================
	const setLimit = useCallback((limit: number) => {
		dispatch({ type: 'SET_LIMIT', payload: limit });
	}, []);

	// =====================================
	const setManaging = useCallback((managing: boolean) => {
		dispatch({ type: 'SET_MANAGING', payload: managing });
	}, []);

	// =====================================
	const setMyTournaments = useCallback((myTournaments: boolean) => {
		dispatch({ type: 'SET_MY_TOURNAMENTS', payload: myTournaments });
	}, []);

	// =====================================
	const setNowPlaying = useCallback((nowPlaying: boolean) => {
		dispatch({ type: 'SET_NOW_PLAYING', payload: nowPlaying });
	}, []);

	// =====================================
	const setNowRegistering = useCallback((nowRegistering: boolean) => {
		dispatch({ type: 'SET_NOW_REGISTERING', payload: nowRegistering });
	}, []);

	// =====================================
	const setPlace = useCallback(
		(place: GooglePlaceResult | null, zoom?: number, isAutocomplete?: boolean) => {
			if (place && place.geometry) {
				if (isAutocomplete && viewport) {
					const zoomLevel = 8;
					const centerLatLng: LatLng = { lat: place.geometry.location.lat, lng: place.geometry.location.lng }; // San Francisco
					const bounds = getMapBounds(centerLatLng, zoomLevel, viewport.width, viewport.height);
					const payload = {
						place: {
							formatted_address: place.formatted_address,
							place_id: place.place_id
						},
						bounds: {
							ne_lat: bounds.northEast.lat,
							ne_lng: bounds.northEast.lng,
							sw_lat: bounds.southWest.lat,
							sw_lng: bounds.southWest.lng
						},
						zoomLevel: zoomLevel,
						center: centerLatLng
					};
					dispatch({ type: 'SET_PLACE', payload });
				} else {
					const bounds = {
						ne_lat: place.geometry.bounds.northeast.lat,
						ne_lng: place.geometry.bounds.northeast.lng,
						sw_lat: place.geometry.bounds.southwest.lat,
						sw_lng: place.geometry.bounds.southwest.lng
					};
					const googleBounds = new google.maps.LatLngBounds(
						new google.maps.LatLng(bounds.sw_lat, bounds.sw_lng),
						new google.maps.LatLng(bounds.ne_lat, bounds.ne_lng)
					);
					const zoomLevel = getBoundsZoomLevel(googleBounds, viewport);
					const center = { lat: place.geometry.location.lat, lng: place.geometry.location.lng };
					const payload = {
						place: {
							formatted_address: place.formatted_address,
							place_id: place.place_id
						},
						bounds,
						zoomLevel: zoom || zoomLevel,
						center
					};
					dispatch({ type: 'SET_PLACE', payload });
				}
			} else {
				dispatch({ type: 'SET_CLEAR_PLACE' });
			}
		},
		[map, viewport]
	);

	// =====================================
	const setPlaceKeyword = useCallback((keyword: string) => {
		dispatch({ type: 'SET_PLACE_KEYWORD', payload: keyword });
	}, []);

	// =====================================
	const setPastEvents = useCallback((past: boolean) => {
		dispatch({ type: 'SET_PAST_DATES', payload: past });
	}, []);

	// =====================================
	const setPage = useCallback((page: number) => {
		dispatch({ type: 'SET_PAGE', payload: page });
	}, []);

	// =====================================
	const setRegisteredFor = useCallback((featured: boolean) => {
		dispatch({ type: 'SET_REGISTERED_FOR', payload: featured });
	}, []);

	// =====================================
	const setSelectedTournamentFilter = useCallback((tournament_filter: string | null) => {
		dispatch({ type: 'SET_SELECTED_TOURNAMENT_FILTER', payload: tournament_filter });

		if (tournament_filter === 'now-playing') {
			dispatch({ type: 'SET_NOW_PLAYING', payload: true });
			return;
		}

		if (tournament_filter === 'now-registering') {
			dispatch({ type: 'SET_NOW_REGISTERING', payload: true });
			return;
		}

		dispatch({ type: 'SET_NOW_REGISTERING', payload: false });
		dispatch({ type: 'SET_NOW_PLAYING', payload: false });
	}, []);

	// =====================================
	const setMtPast = useCallback((mtPast: boolean) => {
		dispatch({ type: 'SET_MT_PAST', payload: mtPast });
	}, []);

	// =====================================
	const setSelectedPartner = useCallback((partner: string | null) => {
		dispatch({ type: 'SET_SELECTED_PARTNER', payload: partner });
	}, []);

	// =====================================
	const resetToInitialState = useCallback((state: TournamentsState) => {
		dispatch({ type: 'RESET_TO_INITIAL_STATE', payload: state });
	}, []);

	const contextValue = useMemo(() => {
		return {
			...state,
			loading: loading,
			data: data || previousData,
			clearDates,
			getTournaments,
			setBounds,
			setDates,
			setFeatured,
			setKeyword,
			setLimit,
			setMyTournaments,
			setManaging,
			setNowPlaying,
			setNowRegistering,
			setPage,
			setPastEvents,
			setPlace,
			setPlaceKeyword,
			setSelectedTournamentFilter,
			setMtPast,
			setRegisteredFor,
			setSelectedPartner,
			resetToInitialState
		};
	}, [
		state,
		loading,
		data,
		previousData,
		clearDates,
		getTournaments,
		setBounds,
		setDates,
		setKeyword,
		setFeatured,
		setLimit,
		setManaging,
		setMyTournaments,
		setNowPlaying,
		setNowRegistering,
		setPage,
		setPastEvents,
		setPlace,
		setPlaceKeyword,
		setSelectedTournamentFilter,
		setMtPast,
		setRegisteredFor,
		setSelectedPartner
	]);

	return <TournamentsContext.Provider value={contextValue}>{children}</TournamentsContext.Provider>;
};
