'use client';

import { faMap } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/ui/components/tournaments-app/button';

import { useMap } from '@/map/lib/context';

export enum ScrollDirection {
	up = 0,
	down = 1
}

/* export const useScrollDirection = () => {
	const threshold = 100;
	const [scrollDir, setScrollDir] = useState(ScrollDirection.up);

	useEffect(() => {
		let previousScrollYPosition = window.scrollY;

		const scrolledMoreThanThreshold = (currentScrollYPosition: number) => Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

		const isScrollingUp = (currentScrollYPosition: number) =>
			currentScrollYPosition > previousScrollYPosition &&
			!(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
			!(currentScrollYPosition > 0 && previousScrollYPosition === 0);

		const updateScrollDirection = () => {
			const currentScrollYPosition = window.scrollY;

			if (scrolledMoreThanThreshold(currentScrollYPosition)) {
				const newScrollDirection = isScrollingUp(currentScrollYPosition) ? ScrollDirection.down : ScrollDirection.up;
				setScrollDir(newScrollDirection);
				previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
			}
		};

		const onScroll = () => window.requestAnimationFrame(updateScrollDirection);

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	return scrollDir;
}; */

export const BottomBar = () => {
	const { mapDisplay, setMapDisplay, showAll } = useMap();

	if (showAll) {
		return null;
	}

	return (
		<div
			style={{
				transition: 'transform 0.3s ease-in-out'
			}}
			className="shadow fixed inset-x-0 bottom-0 z-10 flex items-center justify-center gap-10 border-t border-gray-200 bg-white text-center text-sm backdrop-blur lg:hidden"
		>
			<Button
				className={`absolute bottom-full left-1/2 mb-4 inline-flex !h-12 -translate-x-1/2 items-center gap-2 rounded-full bg-black !px-6 text-sm text-white transition`}
				onClick={() => {
					setMapDisplay(mapDisplay === 'full' ? 'hidden' : 'full');
				}}
			>
				<FontAwesomeIcon icon={faMap} size="lg" />
				{mapDisplay === 'hidden' || mapDisplay === 'display' ? 'Show map' : 'Show list'}
			</Button>
		</div>
	);
};
