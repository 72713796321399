const isDateValid = (date: Date) => {
	return !isNaN(date.getTime());
};

export const convertDateToUnix = (date?: string) => {
	if (date) {
		const dateObj = new Date(date);
		if (isDateValid(dateObj)) {
			return (dateObj.getTime() / 1000).toString();
		}
	}

	return null;
};
