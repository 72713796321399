import { LatLng, MapBounds, Place, SearchType, TournamentsState } from '@/tournament/lib/context';

type ActionType =
	| { type: 'CLEAR_DATES' }
	| { type: 'SET_BOUNDS'; payload: { bounds: MapBounds | null; center?: LatLng; zoomLevel?: number; mapArea: boolean } }
	| { type: 'SET_DATES'; payload: { from?: string; to?: string; months?: number } }
	| { type: 'SET_KEYWORD'; payload: string | null }
	| { type: 'SET_LIMIT'; payload: number }
	| { type: 'SET_MANAGING'; payload: boolean }
	| { type: 'SET_MY_TOURNAMENTS'; payload: boolean }
	| { type: 'SET_NOW_PLAYING'; payload: boolean }
	| { type: 'SET_NOW_REGISTERING'; payload: boolean }
	| { type: 'SET_FEATURED'; payload: boolean }
	| { type: 'SET_PAGE'; payload: number }
	| { type: 'SET_PAST_DATES'; payload: boolean }
	| {
			type: 'SET_PLACE';
			payload: { place: Place; zoomLevel?: number; bounds?: MapBounds; center?: LatLng };
	  }
	| {
			type: 'SET_CLEAR_PLACE';
	  }
	| { type: 'SET_PLACE_KEYWORD'; payload: string }
	| { type: 'SET_REGISTERED_FOR'; payload: boolean }
	| { type: 'SET_MT_PAST'; payload: boolean }
	| { type: 'SET_SELECTED_TOURNAMENT_FILTER'; payload: string | null }
	| { type: 'SET_SEARCH_TYPE'; payload: SearchType }
	| { type: 'SET_SELECTED_PARTNER'; payload: string | null }
	| { type: 'RESET_TO_INITIAL_STATE'; payload: TournamentsState };

export const reducer = (data: TournamentsState, action: ActionType): TournamentsState => {
	switch (action.type) {
		case 'CLEAR_DATES': {
			return {
				...data,
				dates: null,
				mtPast: false,
				myTournaments: false,
				managing: false,
				pastEvents: false
			};
		}
		case 'SET_BOUNDS': {
			const { bounds, center, zoomLevel, mapArea } = action.payload;
			return {
				...data,
				userLocationFetch: false,
				bounds,
				...(center && { center }),
				...(zoomLevel && { zoomLevel }),
				mapArea,
				mtPast: false,
				myTournaments: false,
				managing: false,
				...(mapArea && {
					placeKeyword: null,
					place: null
				}),
				page: 1
			};
		}
		case 'SET_DATES': {
			return {
				...data,
				pastEvents: false,
				page: 1,
				mtPast: false,
				myTournaments: false,
				managing: false,
				dates: action.payload
			};
		}
		case 'SET_KEYWORD': {
			return {
				...data,
				page: 1,
				place: null,
				mapArea: false,
				userLocationFetch: false,
				placeKeyword: 'Anywhere',
				keyword: action.payload
			};
		}
		case 'SET_LIMIT': {
			return {
				...data,
				limit: action.payload
			};
		}
		case 'SET_MANAGING': {
			return {
				...data,
				place: null,
				placeKeyword: 'Anywhere',
				registeredFor: false,
				managing: true
			};
		}
		case 'SET_MY_TOURNAMENTS': {
			return {
				...data,
				place: null,
				placeKeyword: 'Anywhere',
				mapArea: false,
				pastEvents: false,
				nowRegistering: false,
				nowPlaying: false,
				registeredFor: true,
				managing: false,
				myTournaments: action.payload,
				selectedTournamentFilter: null
			};
		}
		case 'SET_MT_PAST': {
			return {
				...data,
				mtPast: action.payload
			};
		}
		case 'SET_NOW_PLAYING': {
			return {
				...data,
				place: null,
				mapArea: false,
				placeKeyword: 'Anywhere',
				clubId: null,
				nowRegistering: false,
				featured: false,
				nowPlaying: action.payload
			};
		}
		case 'SET_NOW_REGISTERING': {
			return {
				...data,
				mapArea: false,
				place: null,
				clubId: null,
				placeKeyword: 'Anywhere',
				nowPlaying: false,
				featured: false,
				nowRegistering: action.payload
			};
		}
		case 'SET_FEATURED': {
			return {
				...data,
				place: null,
				mapArea: false,
				clubId: null,
				placeKeyword: 'Anywhere',
				nowPlaying: false,
				nowRegistering: false,
				featured: action.payload
			};
		}
		case 'SET_PAGE': {
			return {
				...data,
				page: action.payload
			};
		}
		case 'SET_PAST_DATES': {
			return {
				...data,
				pastEvents: action.payload,
				dates: null
			};
		}
		case 'SET_PLACE': {
			return {
				...data,
				...action.payload,
				mtPast: false,
				page: 1,
				clubId: null,
				selectedTournamentFilter: null,
				selectedPartner: null,
				myTournaments: false,
				managing: false,
				userLocationFetch: false,
				placeKeyword: action.payload.place.formatted_address || null,
				mapArea: false
			};
		}
		case 'SET_CLEAR_PLACE': {
			return {
				...data,
				userLocationFetch: false,
				placeKeyword: null,
				clubId: null,
				place: null,
				center: null,
				bounds: null
			};
		}
		case 'SET_PLACE_KEYWORD': {
			return {
				...data,
				clubId: null,
				page: 1,
				userLocationFetch: false,
				placeKeyword: action.payload
			};
		}
		case 'SET_SELECTED_PARTNER': {
			return {
				...data,
				page: 1,
				place: null,
				clubId: null,
				keyword: null,
				placeKeyword: 'Anywhere',
				selectedPartner: action.payload
			};
		}
		case 'SET_SELECTED_TOURNAMENT_FILTER': {
			return {
				...data,
				page: 1,
				place: null,
				clubId: null,
				keyword: null,
				placeKeyword: 'Anywhere',
				selectedTournamentFilter: action.payload,
				myTournaments: false
			};
		}
		case 'SET_REGISTERED_FOR': {
			return {
				...data,
				managing: false,
				clubId: null,
				place: null,
				placeKeyword: 'Anywhere',
				registeredFor: true
			};
		}
		case 'SET_SEARCH_TYPE': {
			return {
				...data,
				clubId: null,
				searchType: action.payload
			};
		}
		case 'RESET_TO_INITIAL_STATE': {
			return {
				...action.payload
			};
		}
		default: {
			return data;
		}
	}
};
