'use client';

import NoDataFound from '@pickleballinc/ui/components/pickleball-app/NoDataFound';

import { PartnersMobile } from '@/components/PartnersMobile';
import { TournamentFilters } from '@/components/TournamentFilters';
import { NoResultsLoading } from '@/tournament/components/NoResultsLoading';
import { TournamentCount } from '@/tournament/components/TournamentCount';
import { TournamentList } from '@/tournament/components/TournamentList';
import { TournamentListLoading } from '@/tournament/components/TournamentListLoading';

import { useTournaments } from '../lib/context';

interface TournamentListWrapperProps {
	tourneyGlobalCount: number;
}

export const TournamentListWrapper = ({ tourneyGlobalCount }: TournamentListWrapperProps) => {
	const { loading, data, page, setPage } = useTournaments();
	const scrollToTop = () => {
		if (typeof window !== 'undefined') {
			window.scrollTo({ top: 0 });
		}
	};

	return (
		<>
			<PartnersMobile />
			<div className="mt-2 block px-6 md:hidden">
				<TournamentFilters />
			</div>
			<TournamentCount globalCount={tourneyGlobalCount} count={data?.tournaments?.totalCount} isLoading={loading} />

			{loading && data?.tournaments.items.length > 0 && <TournamentListLoading count={data?.tournaments?.items?.length} />}
			{loading && data?.tournaments.items.length === 0 && <NoResultsLoading />}
			{!loading && data?.tournaments.items.length === 0 && (
				<div className="my-10">
					<NoDataFound
						application="tournament"
						title={'No Tournaments found'}
						subtitle="Try changing or removing some of your filters or adjusting your search area."
						imageURL={`${process.env.NEXT_PUBLIC_IMAGE_CDN}`}
					/>
				</div>
			)}
			{!loading && data?.tournaments?.items.length > 0 && (
				<TournamentList
					currentPage={page}
					count={data?.tournaments?.totalCount}
					tournaments={data?.tournaments?.items}
					onPaginationChange={(page) => {
						setPage(page);
						scrollToTop();
					}}
				/>
			)}
		</>
	);
};
