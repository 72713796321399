'use client';
import RenderPTAd from '@pickleballinc/ui/components/advertisements/render-pt-ad';
import getCDNURL from '@pickleballinc/ui/components/pickleball-app/utils/getCDNURL';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { useMap } from '@/map/lib/context';
import { useLocation } from '@/providers/location';
import FavoriteTournamentCard from '@/tournament/components/cards/FavoriteTournamentCard';
import { useTournaments } from '@/tournament/lib/context';
import { ZONE_IDS } from '@/types/ads.types';
import { IS_PRODUCTION } from '@/utils/helpers/constants';

import { PPATourneysCarousel } from './PPATournamentCarousel';
import { TournamentPagination } from './TournamentPagination';

interface TournamentListProps {
	currentPage: number;
	count: number;
	tournaments: any[];
	onPaginationChange: (page: number) => void;
}

export const TournamentList = ({ currentPage, count, tournaments, onPaginationChange }: TournamentListProps) => {
	const { page, selectedTournamentFilter, selectedPartner } = useTournaments();
	const { mapDisplay, showAll } = useMap();
	const { location } = useLocation();

	const gridClasses = clsx('grid gap-4 xl:gap-6', {
		'grid-map-visible': mapDisplay === 'display',
		'grid-map-hidden': mapDisplay === 'hidden'
	});

	const getColumns = () => {
		if (window.innerWidth >= 3910) return 9;
		if (window.innerWidth >= 3520) return 8;
		if (window.innerWidth >= 3120) return 7;
		if (window.innerWidth >= 2730) return 6;
		if (window.innerWidth >= 2330) return 5;
		if (window.innerWidth >= 2036) return 4;
		if (window.innerWidth >= 1625) return 3;
		if (window.innerWidth >= 1220) return 2;
		if (window.innerWidth >= 1024) return 1;
		if (window.innerWidth >= 800) return 2;
		return 1;
	};

	const [columns, setColumns] = useState(1);

	useEffect(() => {
		setColumns(getColumns());
		const handleResize = () => setColumns(getColumns());

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			<div className="">
				<div className="my-6">
					{page === 1 && !selectedPartner && !selectedTournamentFilter && !showAll ? (
						<>
							<div className="mx-6">
								<div className={gridClasses}>
									<RenderPTAd
										zoneId={ZONE_IDS.TOURNEY_SEARCH}
										pageId={100003}
										place={0}
										ipAddress={location?.query}
										className="relative"
										isProd={IS_PRODUCTION}
									/>

									{tournaments?.slice(0, columns).map((item: any) => {
										const { id } = item;
										return (
											<FavoriteTournamentCard
												key={item.id}
												{...item}
												tournamentsAppBaseURL={`${process.env.NEXT_PUBLIC_PT_URL}`}
												isTournamentsApp={true}
												moduleUuid={id}
											/>
										);
									})}
								</div>
							</div>
							<div
								className="my-6 bg-blue-900 px-4 py-8 md:py-0"
								style={{
									backgroundImage: `url(${getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/static/ppa-bg.svg`, 1200, 400)})`,
									backgroundPosition: 'center bottom 6rem',
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover'
								}}
							>
								<PPATourneysCarousel />
							</div>
							<div className="mx-6">
								<div className={gridClasses}>
									{tournaments?.slice(columns).map((item: any) => {
										const { id } = item;
										return (
											<FavoriteTournamentCard
												key={item.id}
												{...item}
												tournamentsAppBaseURL={`${process.env.NEXT_PUBLIC_PT_URL}`}
												isTournamentsApp={true}
												moduleUuid={id}
											/>
										);
									})}
								</div>
							</div>
						</>
					) : (
						<div className="mx-6">
							<div className={gridClasses}>
								<RenderPTAd
									zoneId={ZONE_IDS.TOURNEY_SEARCH}
									pageId={100003}
									place={1}
									ipAddress={location?.query}
									className="relative"
									isProd={IS_PRODUCTION}
								/>
								{tournaments?.map((item: any) => {
									const { id } = item;
									return (
										<FavoriteTournamentCard
											key={item.id}
											{...item}
											tournamentsAppBaseURL={`${process.env.NEXT_PUBLIC_PT_URL}`}
											isTournamentsApp={true}
											moduleUuid={id}
										/>
									);
								})}
							</div>
						</div>
					)}
					<div className="mb-6 mt-16">
						{Math.round(count / 25) > 0 && (
							<TournamentPagination
								count={Math.ceil(count / 25)}
								onChange={(_e, page) => onPaginationChange(page)}
								page={currentPage}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
