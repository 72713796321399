import { gql } from '@apollo/client';

export const TOURNAMENT_QUERY = gql`
	query GetTournaments(
		$bounds: BoundsInput
		$endDate: String
		$keyword: String
		$clubId: String
		$limit: Int
		$nowPlaying: Boolean
		$nowRegistering: Boolean
		$featured: Boolean
		$page: Int
		$center: LatLng
		$userLocationFetch: Boolean
		$pastEvents: Boolean
		$userId: String
		$myTournaments: Boolean
		$mtManaging: Boolean
		$mtPast: Boolean
		$tournamentFilter: String
		$partner: String
		$startDate: String
		$requesterUuid: String
	) {
		tournaments(
			bounds: $bounds
			endDate: $endDate
			keyword: $keyword
			clubId: $clubId
			limit: $limit
			nowPlaying: $nowPlaying
			featured: $featured
			nowRegistering: $nowRegistering
			userId: $userId
			page: $page
			center: $center
			userLocationFetch: $userLocationFetch
			myTournaments: $myTournaments
			mtManaging: $mtManaging
			mtPast: $mtPast
			pastEvents: $pastEvents
			partner: $partner
			tournamentFilter: $tournamentFilter
			startDate: $startDate
			requesterUuid: $requesterUuid
		) {
			items {
				id
				dateFrom
				dateTo
				currency
				location
				isCanceled
				isCostPerEvent
				isFavorite
				isFree
				isPrizeMoney
				isRegistrationClosed
				isTournamentCompleted
				lat
				lng
				logo
				price
				registrationCount
				slug
				status
				title
				additionalImages
				hideRegisteredPlayers
				isAdvertiseOnly
			}
			markers {
				id
				lat
				lng
			}
			totalCount
		}
	}
`;
