import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { menuPartners, nationalTours, partners, sanctioned } from '@/components/PartnersList';

interface RemovePartnerSelectionProps {
	partner: string;
	onClick: () => void;
}

const allPartners = [...partners, ...nationalTours, ...sanctioned, ...menuPartners];

export const RemovePartnerSelection = ({ partner, onClick }: RemovePartnerSelectionProps) => {
	return (
		<span
			className="flex cursor-pointer items-center gap-2 rounded-full bg-gray-200 py-1 pl-3 pr-2 text-xs font-normal transition-colors hover:bg-gray-300"
			onClick={onClick}
		>
			{allPartners.find((item) => item.name === partner)?.label || ''}
			<FontAwesomeIcon icon={faTimesCircle} className="block text-sm text-black/80" />
		</span>
	);
};
