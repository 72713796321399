import clsx from 'clsx';

import { useMap } from '@/map/lib/context';
import { EventCardSkeleton } from '@/tournament/components/EventCardSkeleton';

interface TournamentListLoadingProps {
	count?: number;
}

export const TournamentListLoading = ({ count = 24 }: TournamentListLoadingProps) => {
	const { mapDisplay } = useMap();
	const gridClasses = clsx('grid gap-4 xl:gap-6', {
		'grid-map-visible': mapDisplay === 'display',
		'grid-map-hidden': mapDisplay === 'hidden'
	});

	return (
		<div className="mx-6">
			<div className="my-6">
				<div className={gridClasses}>
					{Array.from({ length: count || 24 }, (_, index) => (
						<EventCardSkeleton key={index} />
					))}
				</div>
			</div>
		</div>
	);
};
