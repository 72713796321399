'use client';

import { Footer } from '@pickleballinc/ui/components/pickleball-app/Footer';
import clsx from 'clsx';

import { useMap } from '@/map/lib/context';
import { TournamentListWrapper } from '@/tournament/components/TournamentListWrapper';

interface ListWrapperProps {
	tourneyGlobalCount: number;
}

export const ListWrapper = ({ tourneyGlobalCount }: ListWrapperProps) => {
	const { mapDisplay } = useMap();
	// const { selectedTournamentFilter } = useTournaments();

	if (mapDisplay === 'full') {
		// This is used for mobile view
		return <div className="h-screen"></div>;
	}

	const classes = clsx('w-full', {
		'lg:w-[63%]': mapDisplay === 'display',
		'lg:w-[100%]': mapDisplay === 'hidden'
	});

	return (
		<>
			<div className={classes}>
				<TournamentListWrapper tourneyGlobalCount={tourneyGlobalCount} />
				<div className="block pb-10 md:hidden md:pb-0">
					<Footer
						application="tournament"
						baseUrl={`${process.env.NEXT_PUBLIC_FRONT_URL}`}
						cdnUrl={`${process.env.NEXT_PUBLIC_IMAGE_CDN}`}
					/>
				</div>
			</div>
		</>
	);
};
