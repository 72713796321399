export function calculateZoomLevel(lat: number, lng: number, mapWidth: number, mapHeight: number, desiredRadiusMiles: number) {
	const EARTH_RADIUS_MILES = 3958.8;
	const TILE_SIZE = 256;
	const MAX_LAT = 85.05112878; // Maximum latitude to avoid infinity at the poles

	// Ensure latitude is within bounds
	lat = Math.max(Math.min(MAX_LAT, lat), -MAX_LAT);

	// Convert desired radius from miles to degrees
	const desiredRadiusDegrees = (desiredRadiusMiles / EARTH_RADIUS_MILES) * (180 / Math.PI);

	// Latitude bounds
	const minLat = lat - desiredRadiusDegrees;
	const maxLat = lat + desiredRadiusDegrees;

	// Longitude bounds considering the latitude
	const latRadius = desiredRadiusDegrees / Math.cos((lat * Math.PI) / 180);
	const minLng = lng - latRadius;
	const maxLng = lng + latRadius;

	// Calculate the span in degrees for latitude and longitude
	const latSpan = maxLat - minLat;
	const lngSpan = maxLng - minLng;

	// Calculate zoom level for both latitude and longitude
	const latZoomLevel = Math.log2((360 / latSpan) * (mapHeight / TILE_SIZE));
	const lngZoomLevel = Math.log2((360 / lngSpan) * (mapWidth / TILE_SIZE));

	// Return the minimum zoom level to fit both dimensions
	return Math.min(latZoomLevel, lngZoomLevel);
}
