interface LatLng {
	lat: number;
	lng: number;
}

interface Point {
	x: number;
	y: number;
}

interface Bounds {
	northEast: LatLng;
	southWest: LatLng;
}

export function getMapBounds(centerLatLng: LatLng, zoom: number, mapWidth: number, mapHeight: number): Bounds {
	const scale = Math.pow(2, zoom);
	const worldCoordinateCenter = project(centerLatLng);
	const pixelOffset = {
		x: mapWidth / 2,
		y: mapHeight / 2
	};

	const northEast = {
		x: worldCoordinateCenter.x + pixelOffset.x / scale,
		y: worldCoordinateCenter.y - pixelOffset.y / scale
	};

	const southWest = {
		x: worldCoordinateCenter.x - pixelOffset.x / scale,
		y: worldCoordinateCenter.y + pixelOffset.y / scale
	};

	const neLatLng = unproject(northEast);
	const swLatLng = unproject(southWest);

	return {
		northEast: neLatLng,
		southWest: swLatLng
	};
}

function project(latLng: LatLng): Point {
	const siny = Math.sin((latLng.lat * Math.PI) / 180);
	const x = latLng.lng / 360 + 0.5;
	const y = 0.5 - (0.25 * Math.log((1 + siny) / (1 - siny))) / Math.PI;
	return {
		x: x * 256,
		y: y * 256
	};
}

function unproject(point: Point): LatLng {
	const lng = (point.x / 256 - 0.5) * 360;
	const latRadians = (0.5 - point.y / 256) * 2 * Math.PI;
	const lat = (180 / Math.PI) * (2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);
	return {
		lat: lat,
		lng: lng
	};
}
