'use client';

import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { useTournaments } from '@/tournament/lib/context';

import { SearchMap } from '../../components/SearchMap';
import { useMap } from '../../map/lib/context';

export const MapWrapper = () => {
	const size = useRef<number>();
	const { keyword, selectedTournamentFilter, myTournaments, selectedPartner } = useTournaments();
	const { mapDisplay, setMapDisplay, showAll } = useMap();
	const [isMobile, setIsMobile] = useState<number>();

	useEffect(() => {
		const onResize = () => {
			if (size.current !== window.innerWidth) {
				setIsMobile(window.innerWidth < 1024 ? 1 : 0);

				if (window.innerWidth < 1024) {
					setMapDisplay('hidden');
				} else {
					if (!selectedTournamentFilter && !selectedPartner && !showAll && !keyword && !myTournaments && !keyword) {
						setMapDisplay('display');
					}
				}

				size.current = window.innerWidth;
			}
		};

		onResize();

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [selectedTournamentFilter, selectedPartner, showAll, myTournaments, keyword]);

	const classes = clsx('fixed inset-0 w-full pt-[92px]', {
		hidden: showAll || mapDisplay !== 'full',
		block: !showAll && mapDisplay === 'full'
	});

	if (selectedTournamentFilter || selectedPartner || showAll) {
		return null;
	}

	return (
		<>
			{isMobile === 0 && (
				<div className="transform-[unset] hidden flex-[auto] shrink-[unset] lg:block">
					<div className="sticky top-[176px] z-0">
						<div className="contents">
							<div className="h-[calc(100vh-176px)]">
								<SearchMap />
							</div>
						</div>
					</div>
				</div>
			)}
			{isMobile === 1 && (
				<div
					className={classes}
					style={{
						minHeight: '-webkit-fill-available'
					}}
				>
					<SearchMap />
				</div>
			)}
		</>
	);
};
