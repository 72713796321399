export const NoResultsLoading = () => {
	return (
		<div className="mx-6">
			<div className="my-6 animate-pulse">
				<div className="h-7 w-24 rounded-md bg-gray-200 dark:bg-gray-700" />
				<div className="mt-3 h-6 w-full max-w-64 rounded-md bg-gray-200 dark:bg-gray-700" />
			</div>
		</div>
	);
};
