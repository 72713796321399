'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SearchHeaderContext } from '../lib/context';

interface SearchHeaderProps {
	children: React.ReactNode;
	initialExpanded?: boolean;
}

export const SearchHeaderProvider = ({ children, initialExpanded = true }: SearchHeaderProps) => {
	const [scrollTop] = useState(0);
	const [expanded, setExpanded] = useState<boolean>(initialExpanded);

	useEffect(() => {
		/* const onScroll = () => {
			if (window.scrollY > 0) {
				setExpanded(false);
				setScrollTop(window.scrollY);
			} else {
				setExpanded(true);
				setScrollTop(window.scrollY);
			}
		}; */

		if (initialExpanded) {
			/* onScroll();
			window.addEventListener('scroll', onScroll); */
		} else {
			/* window.addEventListener('scroll', () => setExpanded(false)); */
		}
	}, [initialExpanded]);

	const toggleExpand = useCallback(
		(expanded: boolean) => {
			if ((initialExpanded && scrollTop > 0) || !initialExpanded) {
				setExpanded(expanded);
			}
		},
		[initialExpanded, scrollTop]
	);

	const contextValue = useMemo(() => {
		return {
			scrollTop,
			expanded,
			initialExpanded,
			toggleExpand
		};
	}, [scrollTop, expanded, initialExpanded, toggleExpand]);

	return <SearchHeaderContext.Provider value={contextValue}>{children}</SearchHeaderContext.Provider>;
};
