import { TournamentsState } from '@/tournament/lib/context';

export const composeQueryParams = (state: TournamentsState, searchParams: string) => {
	const {
		keyword,
		dates,
		bounds,
		page,
		clubId,
		limit,
		zoomLevel,
		myTournaments,
		place,
		placeKeyword,
		nowPlaying,
		nowRegistering,
		selectedTournamentFilter,
		center,
		featured,
		mapArea,
		selectedPartner
	} = state;
	const params = new URLSearchParams(searchParams.toString());

	if (keyword && keyword !== '') {
		params.set('keyword', keyword);
	} else {
		params.delete('keyword');
	}

	if (dates?.from) {
		params.set('start_date', (new Date(dates.from).getTime() / 1000).toString());
	}

	if (dates?.to) {
		params.set('end_date', (new Date(dates.to).getTime() / 1000).toString());
	}

	if (dates?.months) {
		params.set('months', dates.months.toString());
	}

	if (clubId) {
		params.set('club_id', clubId);
	} else {
		params.delete('club_id');
	}

	if (bounds) {
		params.set('ne_lat', bounds.ne_lat.toString());
		params.set('ne_lng', bounds.ne_lng.toString());
		params.set('sw_lat', bounds.sw_lat.toString());
		params.set('sw_lng', bounds.sw_lng.toString());
	}

	if (zoomLevel) {
		params.set('zoom_level', zoomLevel.toString());
	}

	if (center) {
		params.set('center_lat', center.lat.toString());
		params.set('center_lng', center.lng.toString());
	}

	if (page) {
		params.set('page', page.toString());
	}

	if (limit) {
		params.set('limit', limit.toString());
	}

	if (featured) {
		params.set('featured', 'true');
	} else {
		params.delete('featured');
	}

	if (myTournaments) {
		params.set('my_tournaments', 'true');
	} else {
		params.delete('my_tournaments');
	}

	if (place?.place_id) {
		params.set('place_id', place.place_id);
	}

	params.set('map_area', mapArea ? 'true' : 'false');

	if (placeKeyword) {
		params.set('place_keyword', placeKeyword);
	} else {
		params.set('place_keyword', 'Anywhere');
	}

	if (nowPlaying) {
		params.set('now_playing', `${nowPlaying}`);
	} else {
		params.delete('now_playing');
	}

	if (nowRegistering) {
		params.set('now_registering', `${nowRegistering}`);
	} else {
		params.delete('now_registering');
	}

	if (selectedTournamentFilter) {
		params.set('tournament_filter', selectedTournamentFilter);
	} else {
		params.delete('tournament_filter');
	}

	if (params.get('show_all')) {
		params.delete('show_all');
	}

	if (selectedPartner) {
		params.set('partner', selectedPartner);
	} else {
		params.delete('partner');
	}

	return params;
};
