import { faFacebook, faInstagram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import getCDNURL from './utils/getCDNURL';
import CustomLink from '../ui/custom-link';
import { getContactUrl } from './utils/getContactUrl';
import { Application } from './utils/nav.config';

export interface FooterProps {
	application?: Application;
	baseUrl: string;
	cdnUrl: string;
}

export const Footer = ({ application, baseUrl, cdnUrl }: FooterProps) => {
	const isTourneyPage = application === 'tournament';
	const isClubsPage = application === 'clubs';
	const isLeaguesPage = application === 'leagues';
	const isLocalhost = baseUrl?.startsWith('localhost');
	const contactUsURL = getContactUrl(isTourneyPage, isClubsPage, isLeaguesPage, isLocalhost);
	const url = `http${isLocalhost ? '' : 's'}://${baseUrl}`;

	return (
		<footer className="flex flex-col justify-center bg-white py-10 pb-16 shadow-xs" id="pb-footer">
			<div className={`flex justify-center ${isTourneyPage ? 'pb-[34px]' : 'pb-10'}`}>
				{isTourneyPage ? (
					<img
						src={`${getCDNURL(`${cdnUrl}/pickleball-app/header-logos/PT_Full.svg`, 320, 84)}`}
						alt="Pickleballtournaments.com"
						width={160}
						height={42}
						className="pr-4"
					/>
				) : application === 'team-leagues' ? (
					<img
						src={`${getCDNURL(`${cdnUrl}/pickleballteamleagues-app/ptl-logo-full.svg`, 160, 30)}`}
						alt="Pickleballteamleagues.com"
						width={160}
						height={30}
					/>
				) : application === 'wpr-rankings' ? (
					<img
						src={`${getCDNURL(`${cdnUrl}/pickleball-app/assets/images/wpr/wpr_footer.svg`, 160, 30)}`}
						alt="Worldpickleballrankings.com"
						width={160}
						height={30}
					/>
				) : isClubsPage ? (
					<img
						src={`${getCDNURL(`${cdnUrl}/pickleball-app/header-logos/PC_Full.svg`, 160, 30)}`}
						alt="pickleballclubs.com"
						width={160}
						height={30}
					/>
				) : isLeaguesPage ? (
					<img
						src={`${getCDNURL(`${cdnUrl}/pickleball-app/header-logos/PL_Full.svg`, 160, 30)}`}
						alt="pickleballclubs.com"
						width={160}
						height={30}
					/>
				) : (
					<img
						src={`${getCDNURL(`${cdnUrl}/pickleball-app/assets/images/logo-full.svg`, 160, 30)}`}
						alt="Pickleball.com"
						width={160}
						height={30}
					/>
				)}
			</div>
			<div className="mx-auto flex w-full max-w-7xl flex-col items-center gap-2 px-2 pt-4 sm:px-4 lg:hidden">
				<div className="flex flex-row gap-3 pb-3">
					<CustomLink
						className="hover:!opacity-60"
						href="https://www.facebook.com/pickleballcom/"
						rel="noopener noreferrer"
						target="_blank"
					>
						<FontAwesomeIcon icon={faFacebook} color="#000000" size="xl" />
					</CustomLink>
					<CustomLink className="hover:!opacity-60" href="https://twitter.com/pickleball_com" rel="noopener noreferrer" target="_blank">
						<FontAwesomeIcon icon={faXTwitter} color="#000000" size="xl" />
					</CustomLink>
					<CustomLink
						className="hover:!opacity-60"
						href="https://www.instagram.com/pickleballdotcom/"
						rel="noopener noreferrer"
						target="_blank"
					>
						<FontAwesomeIcon icon={faInstagram} color="#000100" size="xl" />
					</CustomLink>
					<CustomLink
						className="hover:!opacity-60"
						href="https://www.tiktok.com/@pickleballdotcom?lang=en"
						rel="noopener noreferrer"
						target="_blank"
					>
						<FontAwesomeIcon icon={faTiktok} color="#000000" size="xl" />
					</CustomLink>
					<CustomLink
						className="hover:!opacity-60"
						href="https://www.youtube.com/@Pickleballdotcom"
						rel="noopener noreferrer"
						target="_blank"
					>
						<FontAwesomeIcon icon={faYoutube} color="#000000" size="xl" />
					</CustomLink>
				</div>
			</div>
			<div className="mx-auto flex w-full max-w-7xl flex-col items-center gap-2 border-t border-gray-200 px-2 pt-4 sm:px-4 lg:flex-row lg:gap-0">
				<div className="text-center text-sm text-gray-900">{`© ${moment().get('year')} Pickleball OpCo LLC, All Rights Reserved.`}</div>
				<div className="mx-auto hidden flex-col items-center gap-2 px-8 pt-4 lg:flex">
					<ul className="flex flex-row gap-4 pb-3">
						<li>
							<CustomLink
								className="hover:!opacity-60"
								href="https://www.facebook.com/pickleballcom/"
								rel="noopener noreferrer"
								target="_blank"
								aria-label="Pickleball Facebook"
							>
								<FontAwesomeIcon icon={faFacebook} color="#000000" size="2xl" />
							</CustomLink>
						</li>
						<li>
							<CustomLink
								className="hover:!opacity-60"
								href="https://twitter.com/pickleball_com"
								rel="noopener noreferrer"
								target="_blank"
								aria-label="Pickleball X"
							>
								<FontAwesomeIcon icon={faXTwitter} color="#000000" size="2xl" />
							</CustomLink>
						</li>
						<li>
							<CustomLink
								className="hover:!opacity-60"
								href="https://www.instagram.com/pickleballdotcom/"
								rel="noopener noreferrer"
								target="_blank"
								aria-label="Pickleball Instagram"
							>
								<FontAwesomeIcon icon={faInstagram} color="#000100" size="2xl" />
							</CustomLink>
						</li>
						<li>
							<CustomLink
								className="hover:!opacity-60"
								href="https://www.tiktok.com/@pickleballdotcom?lang=en"
								rel="noopener noreferrer"
								target="_blank"
								aria-label="Pickleball TikTok"
							>
								<FontAwesomeIcon icon={faTiktok} color="#000000" size="2xl" />
							</CustomLink>
						</li>
						<li>
							<CustomLink
								className="hover:!opacity-60"
								href="https://www.youtube.com/@Pickleballdotcom"
								rel="noopener noreferrer"
								target="_blank"
								aria-label="Pickleball Youtube"
							>
								<FontAwesomeIcon icon={faYoutube} color="#000000" size="2xl" />
							</CustomLink>
						</li>
					</ul>
				</div>
				<div className="flex items-center">
					<ul className="m-0 flex flex-wrap justify-center divide-gray-200 p-0">
						<li className="list-none border-r pr-2 text-sm">
							<a
								href={contactUsURL}
								target="_blank"
								className="font-semibold leading-4 text-gray-900 hover:underline hover:underline-offset-2"
							>
								Contact us
							</a>
						</li>
						<li className="list-none border-r px-2 text-sm">
							<CustomLink
								href={`${url}/privacy-policy`}
								className="font-semibold leading-4 text-gray-900 hover:underline hover:underline-offset-2"
							>
								Privacy Policy
							</CustomLink>
						</li>
						<li className="list-none border-r px-2 text-sm">
							<CustomLink
								href={`${url}/terms-of-use`}
								className="font-semibold leading-4 text-gray-900 hover:underline hover:underline-offset-2"
							>
								Terms of Use
							</CustomLink>
						</li>
						<li className="list-none pl-2 text-sm">
							<CustomLink
								href={`${url}/dmca-notice`}
								className="font-semibold leading-4 text-gray-900 hover:underline hover:underline-offset-2"
							>
								DMCA Notice
							</CustomLink>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};
