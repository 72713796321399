import getCDNURL from './utils/getCDNURL';
interface NoDataFoundProps {
	title: string;
	subtitle?: string;
	imageURL: string;
	isTournaments?: boolean;
	application?: 'pickleball' | 'tournament' | 'team-leagues';
}
const NoDataFound = ({ title, subtitle, imageURL, application = 'pickleball' }: NoDataFoundProps) => {
	return (
		<div className="mb-3 mt-1 flex w-full flex-col items-center justify-center px-3 sm:mt-0 sm:px-0">
			<img
				src={
					application === 'tournament'
						? getCDNURL(`${imageURL}/pickleballtournaments-app/no-results-blue.svg`, 320, 233)
						: application === 'team-leagues'
							? getCDNURL(`${imageURL}/pickleballteamleagues-app/no-results-orange.svg`, 320, 233)
							: getCDNURL(`${imageURL}/pickleball-app/static/no-results.svg`, 320, 233)
				}
				width={320}
				height={233}
				alt={`${title}- Illustration`}
				className="mb-10"
			/>
			<h1 className="mb-3 text-xl font-semibold text-gray-900 sm:text-3xl">{title}</h1>
			{subtitle && <h3 className="text-center font-medium text-gray-700">{subtitle}</h3>}
		</div>
	);
};
export default NoDataFound;
