export function getBoundsZoomLevel(bounds: google.maps.LatLngBounds, mapDim?: { width: number; height: number }) {
	const WORLD_DIM = { height: 256, width: 256 };
	const ZOOM_MAX = 21;

	function latRad(lat: number) {
		const sin = Math.sin((lat * Math.PI) / 180);
		const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
		return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
	}

	function zoom(mapPx: number, worldPx: number, fraction: number) {
		return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
	}

	const ne = bounds.getNorthEast();
	const sw = bounds.getSouthWest();

	const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

	const lngDiff = ne.lng() - sw.lng();
	const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

	const latZoom = zoom(mapDim?.height || 0, WORLD_DIM.height, latFraction);
	const lngZoom = zoom(mapDim?.width || 0, WORLD_DIM.width, lngFraction);

	return Math.min(latZoom, lngZoom, ZOOM_MAX);
}
