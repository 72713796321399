import { gql } from '@apollo/client';

export const TOURNAMENT_ID_QUERY = gql`
	query GetTournaments($id: String) {
		tournament(id: $id) {
			dateFrom
			dateTo
			isCanceled
			logo
			registrationCount
			slug
			title
		}
	}
`;
