'use client';

import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { faAngleLeft, faAngleRight, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutsideClick } from '@pickleballinc/react-ui';
import { UnstyledButton } from '@pickleballinc/ui/components/tournaments-app/unstyled-button';
import { GoogleMap } from '@react-google-maps/api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MapEventCards } from '@/tournament/components/MapEventCards';
import { calculateZoomLevel } from '@/tournament/lib/map-radius-zoom';

import { useMap } from '../map/lib/context';
import { useTournaments } from '../tournament/lib/context';
import { SearchMapPoint } from './SearchMapPoint';

const containerStyle = {
	width: '100%',
	height: '100%'
};

export const SearchMap = () => {
	const [tournamentId, setTournamentId] = useState<string | null>(null);
	const initialZoomRef = useRef<boolean>(false);
	const onIdleRef = useRef<NodeJS.Timeout | null>(null);
	const shouldUpdateRef = useRef<boolean>(false);
	const [mouseInteract, setMouseInteract] = useState(false);
	const { viewport } = useMap();

	const mapContainer = useOutsideClick(() => {
		// setActiveItem(undefined);
		setTournamentId(null);
	});

	const { center, zoomLevel, isLoaded, map, mapDisplay, onLoad, onUnmount, setMapDisplay, programmaticZoom, setProgrammaticZoom, setViewPort } =
		useMap();
	const { loading, data, bounds, setBounds, setPage, userLocationFetch } = useTournaments();

	const setUserInteraction = (state: boolean) => {
		setMouseInteract(state);
	};

	const scrollIntoSearchView = () => {
		if (window.scrollY < 286) {
			window.scrollTo({ top: 286, behavior: 'smooth' });
		}
	};

	useEffect(() => {
		const setMapSizeViewport = () => {
			if (mapContainer.current) {
				const boundingClient = mapContainer.current.getBoundingClientRect();
				setViewPort(boundingClient.width, boundingClient.height);
			}
		};

		if (map && isLoaded) {
			setMapSizeViewport();
			window.addEventListener('resize', setMapSizeViewport);
		}

		return () => {
			window.removeEventListener('resize', setMapSizeViewport);
		};
	}, [isLoaded, map]);

	const onDragStart = useCallback(() => {
		setMouseInteract(true);
		shouldUpdateRef.current = true;
	}, []);

	const onIdle = useCallback(() => {
		if (onIdleRef.current) clearTimeout(onIdleRef.current);
		if (mouseInteract) {
			if (map && shouldUpdateRef.current) {
				onIdleRef.current = setTimeout(() => {
					const bounds = map.getBounds();
					if (bounds) {
						const ne = bounds.getNorthEast();
						const sw = bounds.getSouthWest();

						const center = map.getCenter();
						let getCenter: { lat: number; lng: number } | undefined = undefined;
						if (center) {
							getCenter = { lat: center.lat(), lng: center.lng() };
						}
						setBounds({ ne_lat: ne.lat(), ne_lng: ne.lng(), sw_lat: sw.lat(), sw_lng: sw.lng() }, map.getZoom(), getCenter, true);
						// setPage(Math.random());
						// setActiveItem(undefined);
						shouldUpdateRef.current = false;
						setMouseInteract(false);
					}
				}, 600);
			}
		}
		if (programmaticZoom) setProgrammaticZoom(false);
	}, [map, setPage, mouseInteract]);

	const onZoomChanged = useCallback(() => {
		if (map && initialZoomRef.current) {
			shouldUpdateRef.current = true;
			onIdle();
		} else {
			initialZoomRef.current = true;
		}
	}, [map, onIdle, programmaticZoom, setProgrammaticZoom]);

	const handleMapClick = useCallback(() => {
		setTournamentId(null);
	}, []);

	const markers = userLocationFetch ? data?.tournaments?.items : data?.tournaments?.markers;

	const zoom = useMemo(() => {
		let zl = zoomLevel;
		if (!bounds && center && viewport) {
			zl = calculateZoomLevel(center.lat, center.lng, viewport?.width, viewport.height, 200);
		}
		return zl;
	}, [bounds, center, zoomLevel, viewport]);

	return isLoaded ? (
		<div
			ref={mapContainer}
			className="relative"
			onWheel={() => setUserInteraction(true)}
			onMouseDown={() => {
				// setTournamentId(null);
			}}
			style={{ height: '100%', width: '100%', position: 'relative', backgroundColor: 'rgb(230, 227, 223)' }}
		>
			<GoogleMap
				mapContainerStyle={containerStyle}
				options={{
					disableDefaultUI: true,
					mapId: 'e0c106e57fa052f0',
					gestureHandling: 'greedy',
					restriction: {
						latLngBounds: {
							north: 85,
							south: -85,
							west: -180,
							east: 180
						},
						strictBounds: !0
					},
					isFractionalZoomEnabled: true,
					scaleControl: false,
					maxZoom: 22,
					minZoom: 3.5,
					streetViewControl: false
				}}
				center={center}
				zoom={zoom}
				onDragStart={onDragStart}
				onZoomChanged={onZoomChanged}
				onIdle={onIdle}
				clickableIcons={false}
				onLoad={(map) => {
					onLoad(map);
				}}
				onUnmount={onUnmount}
				onClick={handleMapClick}
			>
				{markers?.map((item: any) => {
					return (
						<SearchMapPoint
							key={item.id}
							lat={item.lat}
							lng={item.lng}
							onClick={() => {
								setTournamentId(item.id);
								scrollIntoSearchView();
							}}
						/>
					);
				})}

				{/* <div className="absolute inset-x-0 top-0 bg-white p-1">{JSON.stringify({ center })}</div> */}

				<div className="absolute left-6 top-6 hidden flex-row gap-px md:flex">
					{mapDisplay !== 'full' && (
						<UnstyledButton
							className="flex size-10 items-center justify-center rounded-lg bg-white hover:bg-gray-100"
							onClick={() => {
								setMapDisplay('full');
							}}
						>
							<FontAwesomeIcon icon={faAngleLeft} className="block" />
						</UnstyledButton>
					)}
					<UnstyledButton
						className={`flex items-center justify-center rounded-lg bg-white hover:bg-gray-100 ${mapDisplay !== 'full' ? 'size-10' : 'h-10 px-4'}`}
						onClick={() => {
							if (mapDisplay === 'display') {
								setMapDisplay('hidden');
							} else {
								setMapDisplay('display');
							}
						}}
					>
						<FontAwesomeIcon icon={faAngleRight} className="block" />
						{mapDisplay === 'full' && <span className="ml-2 text-xs font-semibold leading-none">Show list</span>}
					</UnstyledButton>
				</div>

				<div className="absolute right-6 top-6 flex flex-col gap-px">
					<UnstyledButton
						className="flex size-10 items-center justify-center rounded-t-lg bg-white hover:bg-gray-100"
						onClick={() => {
							const currentZoom = map?.getZoom();
							if (currentZoom) {
								setMouseInteract(true);
								map?.setZoom(currentZoom + 1);
							}
						}}
					>
						<FontAwesomeIcon icon={faPlus} className="block" />
					</UnstyledButton>
					<UnstyledButton
						className="flex size-10 items-center justify-center rounded-b-lg bg-white hover:bg-gray-100"
						onClick={() => {
							const currentZoom = map?.getZoom();
							if (currentZoom) {
								setMouseInteract(true);
								map?.setZoom(currentZoom - 1);
							}
						}}
					>
						<FontAwesomeIcon icon={faMinus} className="block" />
					</UnstyledButton>
				</div>
				{tournamentId && <MapEventCards id={tournamentId} />}
				{loading && (
					<div className="absolute left-1/2 top-6 flex size-12 -translate-x-1/2 items-center justify-center rounded-lg bg-white">
						<FontAwesomeIcon icon={faSpinnerThird} spin className="text-black" />
					</div>
				)}
			</GoogleMap>
		</div>
	) : (
		<div className="relative flex size-full items-center justify-center" style={{ backgroundColor: 'rgb(230, 227, 223)' }}>
			<FontAwesomeIcon icon={faSpinnerThird} spin className="text-black" size="xl" />
		</div>
	);
};
