import { faPickleball } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import { motion } from 'framer-motion';
import { useState } from 'react';

export const SearchMapPoint = ({ lat, lng, onClick }: { lat: number; lng: number; onClick: () => void }) => {
	const [visited, setVisited] = useState(false);

	return (
		<OverlayViewF position={{ lat, lng }} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
			<div>
				<div className="flex size-0 items-center justify-center">
					<div className="pointer-events-auto mb-0 mr-0">
						<div className="m-0 w-auto overflow-visible p-0">
							<motion.div
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								exit={{ scale: 0 }}
								className="relative flex items-center"
								style={{ transformOrigin: '50% 50%' }}
								transition={{
									type: 'spring',
									stiffness: 400,
									damping: 20,
									delay: Math.random() * 0.3
								}}
								onClick={(e) => {
									e.stopPropagation();
									setVisited(true);
									onClick();
								}}
							>
								<div className={`relative`}>
									<div
										className="flex size-6 cursor-pointer items-center justify-center rounded-full bg-white text-sm font-semibold shadow-md hover:bg-gray-200"
										style={{
											transformOrigin: '50% 50%',
											boxShadow:
												'0 2px 4px rgba(0,0,0,0.18), 0px 2px 4px 0px rgba(0, 0, 0, 0.18), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)'
										}}
									>
										<FontAwesomeIcon icon={faPickleball} size="sm" className={`${visited ? 'text-gray-300' : 'text-blue-500'}`} />
									</div>
								</div>
							</motion.div>
						</div>
					</div>
				</div>
			</div>
		</OverlayViewF>
	);
};
