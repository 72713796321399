import { faAngleDown, faSlidersUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch } from '@pickleballinc/ui/components/tournaments-app/switch';
import { useRef, useState } from 'react';

import { useSession } from '@/lib/SessionProvider';
import { useMap } from '@/map/lib/context';
import { useTournaments } from '@/tournament/lib/context';
import { useOutsideClick } from '@/utils/hooks/outside-click';

interface FilterOptionItem extends React.HTMLProps<HTMLDivElement> {
	children: React.ReactNode;
	checked: boolean;
	onSwitchChange: (checked: boolean) => void;
}

const FilterOptionItem = (props: FilterOptionItem) => {
	const { children, checked, onSwitchChange, id, ...rest } = props;
	return (
		<div
			className="flex w-full cursor-pointer items-center justify-between gap-2 px-4 py-3 text-sm font-medium hover:bg-gray-100"
			onClick={() => {
				onSwitchChange(!checked);
			}}
			{...rest}
		>
			{children}
			<Switch
				id={id}
				checked={checked}
				onCheckedChange={(checked) => {
					onSwitchChange(checked);
				}}
				size="sm"
			/>
		</div>
	);
};

export const TournamentFilters = () => {
	const { session } = useSession();

	const [visible, setVisible] = useState(false);

	const { setMapDisplay } = useMap();
	const {
		myTournaments,
		setMyTournaments,
		managing,
		setManaging,
		registeredFor,
		setRegisteredFor,
		setMtPast,
		mtPast,
		setSelectedTournamentFilter,
		setSelectedPartner
	} = useTournaments();

	const triggerReg = useRef<HTMLDivElement>(null);
	const ref = useOutsideClick((event: MouseEvent) => {
		if (triggerReg.current && !triggerReg.current.contains(event.target as Node)) {
			setVisible(false);
		}
	});

	return (
		<>
			{session && session.user && myTournaments && (
				<div className="relative z-10 my-auto md:z-40">
					<div
						onClick={(e) => {
							e.stopPropagation();
							setVisible(!visible);
						}}
						className="flex h-12 cursor-pointer select-none items-center gap-2 rounded-md border border-gray-200 px-4 hover:border-black hover:bg-gray-100"
						ref={triggerReg}
					>
						<span className="flex flex-1 items-center gap-2 text-sm font-medium">
							<FontAwesomeIcon icon={faSlidersUp} className="text-xs" />
							{myTournaments ? (
								<>
									{registeredFor ? 'Registered for' : managing ? 'Managing' : 'My tournaments'}
									<span className="rounded-full text-xs">/</span>
									{mtPast ? 'Past' : 'Future'}
								</>
							) : null}
							<FontAwesomeIcon icon={faAngleDown} className="ml-auto text-xs md:ml-0" />
						</span>
					</div>
					{visible && (
						<div
							ref={ref}
							className="absolute right-0 top-full z-50 mt-2 w-max rounded-md bg-white py-4 md:w-56"
							style={{ boxShadow: '0 3px 12px 0 rgb(0 0 0/0.15)' }}
						>
							<>
								<FilterOptionItem
									id="my-tournaments"
									checked={myTournaments}
									onSwitchChange={(checked) => {
										setMyTournaments(checked);
										setSelectedTournamentFilter(null);
										setSelectedPartner(null);
										if (checked) {
											setMapDisplay('hidden');
										} else {
											setMapDisplay('display');
										}
									}}
								>
									My tournaments
								</FilterOptionItem>
								<div className="my-2 border-t border-gray-200" />
							</>

							{myTournaments ? (
								<>
									<FilterOptionItem
										id="registered-for"
										checked={registeredFor}
										onSwitchChange={(checked) => setRegisteredFor(checked)}
									>
										Registered for
									</FilterOptionItem>
									<FilterOptionItem id="managing" checked={managing} onSwitchChange={(checked) => setManaging(checked)}>
										Managing
									</FilterOptionItem>
									<div className="my-2 border-t border-gray-200" />
									<FilterOptionItem id="future" checked={!mtPast} onSwitchChange={() => setMtPast(false)}>
										Future
									</FilterOptionItem>
									<FilterOptionItem id="past" checked={mtPast} onSwitchChange={() => setMtPast(true)}>
										Past
									</FilterOptionItem>
								</>
							) : null}
						</div>
					)}
				</div>
			)}
		</>
	);
};
