export const getContactUrl = (isTourneyPage?: boolean, isClubsPage?: boolean, isLeaguesPage?: boolean, isLocalHost?: boolean) => {
    if (isLocalHost) {
        return 'https://pickleball.freshdesk.com/en/support/home';
    } else if (isTourneyPage) {
        return 'https://pickleballtournaments-help.freshdesk.com/en/support/home';
    } else if (isClubsPage) {
        return 'https://pickleballclubs.freshdesk.com/en/support/home';
    } else if (isLeaguesPage) {
        return 'https://pickleballleagues.freshdesk.com/en/support/home';
    }
    return 'https://pickleball.freshdesk.com/en/support/home';
}
